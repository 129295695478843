

:root{
    --light: #fff;
}

.brain-box.landing{
    width: 100%;
    height: 100vh;
    animation: fadeOut 2s linear 8s forwards;
}

@keyframes fadeOut {
    to{opacity: 0;}
}


.select-lang{
    opacity: 0;
    animation: fadeIn 1.5s linear 1s forwards;
}

@keyframes fadeIn {
    to{opacity: 1;}
}