.ReactModal__Overlay--after-open {
  background-color: transparent !important;
  backdrop-filter: blur(5px);
  z-index: 100;
}
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  object-position: center;
  transform: translate(-50%, -50%) scale(0);
  transition: transform cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;

  &:focus {
    outline: none;
  }
}
.ReactModal__Overlay--after-open .modal {
  transform: translate(-50%, -50%) scale(1);
}

.icon-close {
  position: absolute;
  font-size: 3rem;
  right: -4rem;
  top: -4rem;
  transition: 0.1s linear;
  cursor: pointer;
  :hover {
    transform: scale(1.03);
  }
  :active {
    transform: scale(0.98);
  }
}
.modal-project-detail {
  cursor: ns-resize;
  min-width: 40rem;
  width: 100%;
  height: 100%;
  max-width: 100rem;
  max-height: 65vh;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 1rem;
  overflow-y: auto;
  .detail-image-box img {
    display: block;
  }
}
.detail-text-box {
  position: absolute;
  top: 10%;
  right: -10rem;
  // border: .2px solid #fff;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 40rem;
  padding: 2rem;
  background-color: rgba(29, 29, 29, 0.6);
  backdrop-filter: blur(3px);
  color: #fff;
  cursor: default;
  a {
    color: inherit;
    :hover {
      color: var(--primary);
    }
  }
  h5 {
    margin-top: 0.5rem;
  }
  .detail-text-box__footer {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dev-tool {
      color: var(--primary);
    }
    .icon {
      display: inline-block;
      font-size: 2rem;
      cursor: pointer;
    }
  }
}
