header {
  position: relative;
  z-index: 99;
  width: 100%;
  height: 15rem;
  .header-fixed {
    position: fixed;
    width: 100%;
  }
  .header-inner {
    width: 100%;
    max-width: 1920px;
    @include center;

    @include flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem;
    z-index: 99;
    .header-logo {
      width: 8rem;
      .header-logo__flame {
        width: 8rem;
        height: 8rem;
        background-color: var(--primary);
        padding: 1rem;
        transition: 0.2s ease;
      }
      .header-logo__flame.active {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }
    }
    .header-navbar {
      // display: none;
      width: 100%;
      @include media(xl) {
        display: block;
        margin-left: 10rem;
      }
      ul {
        @include media(xl) {
        }
        width: 100%;
        list-style: none;
        @include flex;
        justify-content: flex-start;

        li {
          cursor: pointer;
          min-width: 12rem;
          text-transform: uppercase;
          cursor: pointer;
          display: inline-block;
          text-align: center;

          a {
            cursor: pointer;
            text-decoration: none;
            color: var(--light);
            font-size: 1.6rem;
            font-weight: 200;
            letter-spacing: 1.8px;
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
            transition: all 0.2s linear;
          }
          &:hover {
            a {
              font-weight: 700;
              color: #fff;
              text-shadow: 0px 0px 3px #fff, 0px 0px 10px var(--primary),
                0px 0px 15px var(--primary), 0 0 50px var(--primary);
            }
          }
          a.active {
            font-weight: 700;
            color: #fff;
            text-shadow: 0px 0px 3px #fff, 0px 0px 10px var(--primary),
              0px 0px 15px var(--primary), 0 0 50px var(--primary);
          }
        }
      }
      ul.active {
        li {
          @for $i from 1 through 6 {
            &:nth-child(#{$i}) {
              animation: moveUp
                0.3s
                cubic-bezier(0.91, 0.18, 0.21, 1.6)
                backwards
                $i *
                0.1 +
                s;
            }
          }
        }
      }
      ul.unActive {
        li {
          @for $i from 1 through 6 {
            &:nth-child(#{$i}) {
              animation: moveDown
                0.3s
                cubic-bezier(0.91, 0.18, 0.21, 1.6)
                both
                $i *
                0.1 +
                s;
            }
          }
        }
      }
    }
    .header-hamberger {
      .hamberger-box {
        position: relative;
        padding: 1rem 0.5rem;
        transition: 0.2s ease;
        background-color: var(--primary);
        cursor: pointer;
        span {
          display: block;
          width: 3rem;
          height: 2px;
          background-color: rgb(255, 246, 253);
          &:not(:last-child) {
            margin-bottom: 0.8rem;
          }
        }
      }
    }
    .hamberger-box.active {
      background-color: rvar(--primary);
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      span:first-child {
        transform: rotate(45deg) translateX(7px);
      }
      span:last-child {
        transform: rotate(-45deg) translate(7px, 0px);
      }
    }
  }

  .header-navbar.onScroll-bg {
    ul {
      flex-direction: column;
      position: absolute;
      top: 80%;
      right: 3rem;
      width: 20rem;
      border-radius: 0.3rem;
      overflow: hidden;
      li {
        padding: 2rem 5rem;
        background-color: var(--dark);
        backdrop-filter: blur(5px);
      }
    }
  }
}

// swicth
.language {
  margin-right: 1rem;
}
.switch {
  position: relative;
  display: inline-block;
  width: 6.5rem;
  height: 3rem;
  .lang-text {
    position: absolute;
    font-weight: 600;
    font-size: 1.6rem;
    z-index: 3;
    top: 50%;
    text-transform: uppercase;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    transform: translate(150%, -50%);
    color: rgb(255, 246, 253);
  }
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--dark);
  border: 1px solid var(--primary);
  -webkit-transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.slider:before {
  position: absolute;
  content: "";
  height: 2.9rem;
  width: 2.9rem;
  left: 0px;
  background-color: var(--primary);
  -webkit-transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

input:checked ~ .lang-text {
  transform: translate(30%, -50%);
}
input:checked + .slider {
  background-color: var(--primary);
}
input:checked + .slider::before {
  background-color: var(--light);
}
// input:focus + .slider {
//   box-shadow: 0 0 1px #2196F3;
// }

input:checked + .slider:before {
  -webkit-transform: translateX(3.5rem);
  -ms-transform: translateX(3.5rem);
  transform: translateX(3.5rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
