.landing-page {
  @include middle-box;
  h1 {
    text-align: center;
    color: rgb(255, 246, 253);
    white-space: nowrap;
    border-right: 0.15em solid rgb(242, 108, 79);
    overflow: hidden;
    font-size: 6rem;

    &:nth-child(1) {
      animation: typing 3.5s steps(30, end) backwards,
        blink-caret 0.5s step-end infinite, gone 1s linear 3.6s forwards;
    }
    &:nth-child(2) {
      animation: typing 3.5s steps(30, end) 3.8s backwards,
        blink-caret 0.5s step-end 3.8s backwards, gone 1s linear 9s forwards;
    }
  }
}

@keyframes typing {
  from {
    width: 0;
    opacity: 1;
    visibility: visible;
  }
  to {
    width: 100%;
    opacity: 1;
    visibility: visible;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: rgb(242, 108, 79);
  }
}

@keyframes gone {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}
