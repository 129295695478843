/** @format */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;600;800&display=swap");

html,
body {
  font-family: "Poppins", sans-serif;
  font-size: 50.5%;
  line-height: 1.6;
  @include media(sx) {
    font-size: 60.5%;
  }
  @include media(xl) {
    font-size: 62.5%;
  }
  @include media(xxl) {
    font-size: 72.5%;
  }
}

h1 {
  font-size: 3.8rem;
  font-weight: 800;
}
h2 {
  font-weight: 600;
  font-size: 3rem;
}
h3 {
  font-weight: 600;
  font-size: 2rem;
}
h4 {
  font-weight: 400;
  font-size: 1.8rem;
}
h5 {
  font-weight: 600;
  font-size: 1.6rem;
}
p {
  font-size: 1.4rem;
  font-weight: 400;
}
p.caption {
  font-weight: 400;
  font-size: 1rem;
}
span {
  display: inline-block;
}

a.text-link {
  color: rgb(242, 108, 79);
  &:hover {
    text-shadow: 0 0px 5px rgb(242, 108, 79), 0 0px 20px rgb(242, 108, 79);
    color: #f2f2f2;
  }
}
