/** @format */

// light color : rgb(255,246,253);
// dark color:  rgb(34,34,34);
// orange color: rgb(242,108,79);
// gray color : #77777;
// green color: #00ffbb;
//var()

//for base
:root {
  --dark: rgb(34, 34, 34);
}

//light theme
html[theme="lg"] {
  --primary: rgb(242, 108, 79);
  --light: rgb(34, 34, 34);
  --dark: rgb(255, 255, 255);
  --green: #00ffbb;
}

//dark theme
html[theme="dk"] {
  --primary: rgb(242, 108, 79);
  --light: rgb(255, 255, 255);
  --dark: rgb(34, 34, 34);
  --gray: #77777;
  --green: #00ffbb;
}
