// home page

.sub-about {
  position: relative;
  background-color: rgb(34, 34, 34);
  padding: 8rem 10rem;
  z-index: 1;
  @include flex;

  justify-content: center;
  flex-wrap: wrap;
  @include media(xl) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  @include media(lg) {
    padding: 8rem 22rem;
  }
  .sub-about_left {
    max-width: 45rem;
    color: rgb(255, 255, 255);
    h5 {
      margin: 1rem 0 1.5rem;
    }
    p {
    }
  }
  .experience {
    @include flex;
    align-items: center;
    margin-top: 3rem;
    span {
      display: inline-block;
      &:first-child {
        line-height: 1;
        font-size: 10rem;
        color: rgb(242, 108, 79);
      }
      &:last-child {
        margin-top: -0.8rem;
        font-size: 3rem;
        font-weight: 100;
        margin-left: 1rem;
        color: #fff;
        max-width: 20rem;
      }
    }
  }

  .sub-about_right {
    max-width: 45rem;
    color: #fff;
    h1.text-title-extra {
      letter-spacing: 2px;
      font-size: 4.2rem;
    }
    h3 {
      margin: 1rem 0;
      font-weight: 400;
      letter-spacing: 1.5px;
    }
    > a.text-link {
      @include flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12rem;
      font-size: 3rem;
    }
    .footer-social {
      display: flex;
      font-size: 5rem;
      margin-top: 1.2rem;
      > a {
        display: block;
        &:not(:last-child) {
          margin-right: 3rem;
        }
      }
    }
  }
}

// for ligh theme
html[theme="lg"] {
  .sub-about {
    border-radius: 8rem;
  }
}
