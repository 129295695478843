html[theme="lg"] {
  .hero-stage {
    background-image: url("../../assets/imgs/background/bg-hero-light.jpg");
    background-position: center 55%;
  }
}
html[theme="dk"] {
  .hero-stage {
    background-image: url("../../assets/imgs/background/bg-hero.jpg");
  }
}
.hero-stage {
  @include center;
  background-color: var(--dark);
  width: 100%;
  max-width: 1920px;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 50rem;
  overflow-y: hidden;
  padding: 0 10rem;

  div.hero-content {
    @include flex;
    justify-content: center;
    flex-wrap: wrap;
    @include media(xl) {
      flex-wrap: nowrap;
    }
    .hero_name {
      position: relative;
      width: 45rem;
      z-index: 2;
      @include media(xl) {
        z-index: 1;
      }

      p {
        position: relative;

        font-weight: 600;
        font-size: 7rem;
        letter-spacing: 8px;
        span {
          color: var(--primary);
        }
        @include media(xl) {
          left: 30rem;
        }
        @include media(xxl) {
          font-size: 10rem;
        }
      }
    }
    .hero_profile-pic {
      flex-basis: 40rem;
      opacity: 0.5;
      position: absolute;

      @include media(xl) {
        position: relative;
        opacity: 1;
        z-index: 1;
      }
    }
    .hero_detail {
      position: relative;
      top: 0rem;
      width: 40rem;
      @include media(xl) {
        top: 15rem;
      }
      h3 {
        color: var(--primary);
        letter-spacing: 1.5px;
        text-decoration: underline;
      }
      h5 {
        margin: 1rem 0 1.5rem;
      }
      p {
        color: var(--gray);
      }
    }
  }
}
.dot-rotate {
  @include middle-box;
  position: absolute;
  width: 55rem;
  height: 55rem;
  border-radius: 50%;

  animation: rotateHero 10s linear infinite;
  transform-origin: 0 0;
  .green-dot {
    position: relative;
    top: 5%;
    left: 45%;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: var(--green);
  }
  .green-dot.dot-1 {
    top: 90%;
  }
}
