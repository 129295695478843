/** @format */

// css function

// responsive
@mixin media($repons) {
  // phone
  @if ($repons == sx) {
    @media screen and (min-width: 600px) {
      @content;
    }
  }
  // tablet portrait
  @if ($repons == md) {
    @media screen and (min-width: 769px) {
      @content;
    }
  }
  // tablet landscape
  @if ($repons == xl) {
    @media screen and (min-width: 992px) {
      @content;
    }
  }
  // normal desktop
  @if ($repons == lg) {
    @media screen and (min-width: 1160px) {
      @content;
    }
  }
  @if ($repons == xlg) {
    @media screen and (min-width: 1400px) {
      @content;
    }
  }
  @if ($repons == xxl) {
    @media screen and (min-width: 1800px) {
      @content;
    }
  }
}

@mixin textrow($row: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
// icon

@mixin icon($url) {
  content: "";
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url($url);
}

//  spece
@mixin space($width, $height) {
  width: $width;
  height: $height;
}

// center middle object

@mixin middle {
  margin-left: auto;
  margin-right: auto;
  @media (min-height: 700px) and (min-width: 769px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@mixin middle-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@mixin center {
  margin-left: auto;
  margin-right: auto;
}
@mixin unCenter {
  margin-left: unset;
  margin-right: unset;
}
// flex

@mixin flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
