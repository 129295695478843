/** @format */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  &::before,
  &::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
}
html,
body {
  background-color: var(--dark);
  color: var(--light);
  transition: 0.2s ease;
}
img {
  width: 100%;
}
