.select-lang {
  position: absolute;
  max-width: 60rem;
  @include middle-box;
  top: 40%;
  h1 {
    font-size: 4rem;
    color: rgb(242, 108, 79);
  }
  ul {
    list-style: none;
    @include flex;
    justify-content: center;
    width: 100%;
    margin-top: 5rem;
    li {
      padding: 0 2rem;
      font-size: 1.8rem;
      color: rgb(255, 246, 253);
      cursor: pointer;
      transition: 0.2s ease;
      &:first-child {
        border-right: 3px solid rgb(242, 108, 79);
      }
      &:hover {
        text-shadow: 0px 2px 15px rgb(242, 108, 79);
      }
      &:active {
        text-shadow: 0px 2px 5px rgb(242, 108, 79);
      }
    }
  }
}
