.btn {
  cursor: pointer;
  display: inline-block;
  font-size: 1.4rem;
  text-transform: uppercase;
  text-decoration: unset;
  letter-spacing: 1.5px;
  padding: 0.35rem 2rem;

  &:focus {
    outline: none;
  }
}

.btn-primary {
  color: rgb(242, 108, 79);
  border: 2px solid rgb(242, 108, 79);
  background-color: transparent;
  position: relative;
  transition: 0.3s ease, transform 0.1s;

  z-index: 2;
  &::before {
    content: "";
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: rgb(242, 108, 79);
    transition: 0.3s ease;
  }
  &::after {
    content: "";
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgb(255, 246, 253);
    transition: 0.3s ease;
  }
  &:hover {
    color: #fff;
    border: 2px solid #fff;
    &::before {
      width: 100%;
    }
  }
  &:active {
    transform: scale(0.95);
    overflow: hidden;
    &::after {
      width: 100%;
      animation: leftToRight 0.1s forwards;
    }
  }
}
.btn-primary.linked {
  color: rgb(247, 206, 72);
  border: 2px solid rgb(247, 206, 72);

  &::before {
    background-color: rgb(247, 206, 72);
  }
  &:hover {
    color: #fff;
    border: 2px solid #fff;
  }
}
@keyframes leftToRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
