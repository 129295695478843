// oragne line
.sub-title {
  color: rgb(242, 108, 79);
  @include flex;
  align-items: center;
  h3 {
    display: inline-block;
    text-decoration: underline;
    text-transform: initial;
    letter-spacing: 2px;
  }
  span {
    display: inline-block;
    height: 3px;
    width: 8rem;
    background-color: rgb(242, 108, 79);
    margin-left: 0.5rem;
  }
}

.page-title {
  display: inline-block;
  font-size: 6rem;
  letter-spacing: 4px;
  transform: rotate(-90deg) translate(-90%, -5rem);
}
