// project page

.list-wrapper {
  width: calc(100% - 20rem);
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  column-gap: 5rem;
  row-gap: 5rem;
  height: 70vh;
  overflow-y: auto;
  padding: 3rem 15rem 2rem 2rem;
  margin-top: -9rem;
}
.card__item-list {
  position: relative;
  cursor: default;
  width: 100%;
  max-width: 24.7rem;
  height: 31.1rem;
  border-radius: 1.5rem;
  overflow: hidden;
  box-shadow: -6px -2px 10px rgba(128, 128, 131, 0.3),
    0px 2px 5px rgba(0, 0, 0, 0.5), 0px 5px 15px rgba(18, 18, 19, 0.5);
  transition: all 0.35s ease;
  text-align: center;

  .card__preview-img {
    width: 100%;
    height: 100%;
    transition: all 0.2s ease;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    img {
      width: 100%;
      height: 100%;
      object-position: top;
      object-fit: cover;
    }
  }
  .title {
    display: inline-block;
    opacity: 0;
    padding: 3rem 2rem;
    transform: translateY(-250%);
    transition: all 0.15s ease;
    font-size: 1.8rem;
    word-wrap: break-word;
    text-align: center;
    color: var(--light);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }
  button {
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%) translateY(8rem);
    transition: all 0.2s ease-out;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }
  &:hover button {
    transform: translateX(-50%) translateY(0rem);
  }
  &:hover .card__preview-img {
    height: 40%;
  }
  &:hover .title {
    transform: translateY(0);
    opacity: 1;
  }
  &:hover {
    transform: scale(1.05);
    border-radius: 0px;
    box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.7),
      0px 0px 15px rgba(255, 255, 255, 0.5),
      0px 5px 20px rgba(128, 128, 131, 0.5), 0px 2px 5px rgba(0, 0, 0, 0.8),
      0px 5px 15px rgba(18, 18, 19, 0.7);
    animation: shadow-moving 4s linear infinite 1s;
  }
}

.card__item-list.already-read {
  & .title {
    transform: translateY(0);
    opacity: 1;
  }
  & button {
    transform: translateX(-50%) translateY(0rem);
  }
  & .card__preview-img {
    height: 40%;
  }
  &:hover {
    animation: shadow-moving-linked 4s linear infinite 1s;
  }
}

// when hover other item on blur
.card__item-list.active-blur {
  filter: blur(2px);
}
.list-wrapper.border-scroll {
  border-top: 2px solid rgba(242, 108, 79, 0.6);
}

@keyframes shadow-moving {
  0%,
  100% {
    box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.7),
      -6px -6px 15px rgba(242, 108, 79, 0),
      0px 0px 15px rgba(255, 255, 255, 0.5),
      0px 5px 20px rgba(128, 128, 131, 0.5), 0px 2px 5px rgba(0, 0, 0, 0.8),
      0px 5px 15px rgba(18, 18, 19, 0.7);
  }
  40%,
  70% {
    box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.7),
      6px 6px 15px rgba(242, 108, 79, 1), 0px 0px 15px rgba(255, 255, 255, 0.5),
      0px 5px 20px rgba(128, 128, 131, 0.5), 0px 2px 5px rgba(0, 0, 0, 0.8),
      0px 5px 15px rgba(18, 18, 19, 0.7);
  }
}

@keyframes shadow-moving-linked {
  0%,
  100% {
    box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.7),
      -6px -6px 15px rgba(247, 206, 72, 0),
      0px 0px 15px rgba(255, 255, 255, 0.5),
      0px 5px 20px rgba(128, 128, 131, 0.5), 0px 2px 5px rgba(0, 0, 0, 0.8),
      0px 5px 15px rgba(18, 18, 19, 0.7);
  }
  40%,
  70% {
    box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.7),
      6px 6px 15px rgba(247, 206, 72, 1), 0px 0px 15px rgba(255, 255, 255, 0.5),
      0px 5px 20px rgba(128, 128, 131, 0.5), 0px 2px 5px rgba(0, 0, 0, 0.8),
      0px 5px 15px rgba(18, 18, 19, 0.7);
  }
}
