.skills {
  padding: 6rem;
  width: 100%;
  height: 70vh;
}
.skills-box {
  width: calc(100% - 20rem);
  margin-left: auto;
  position: relative;
}

.skill-lists {
  position: absolute;
  width: 70rem;
  height: 70rem;
  top: 50%;
  left: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid #000;
  transform: rotate(0deg) translate(-50%, -50%);
  // transform-origin: center center;
  transition: 0.4s;
  animation: rolling 20s linear infinite;
}
.bubble-card {
  position: absolute;
  top: 50%;
  left: 46%;

  transform-origin: center top;
  transform: translateX(-50%) rotate(90deg);
  width: 80px;
  height: calc(50% + 30px);
  line-height: 30px;
  text-align: center;

  img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
  .bubble-detail {
    position: absolute;
    z-index: 2;
    bottom: -10rem;
    left: 50%;
    border-radius: 50%;
    border: 1px solid red;
    transform: translate(-50%, -50%);
    transition: 0.3s;
    color: #000;
    // animation: rolling 8s linear infinite reverse;
  }
}

.brain-box {
  width: 100%;
  height: 70vh;
  position: relative;
  cursor: pointer;

  #brain {
    @include middle-box;
    width: 100%;
    max-width: 40rem;
    height: 40rem; //20
    z-index: 2;
    // opacity: 0;

    path {
      fill: transparent;
      stroke: var(--light);
      stroke-width: 2px;
      stroke-dasharray: 25726.482421875;
      stroke-dashoffset: 25726.482421875;
      animation: dashoffset 8s ease forwards 1s;
    }
  }
  #brain-fill {
    object-position: center;
    @include middle-box;
    width: 100%;
    opacity: 0;
    max-width: 80rem;
    height: 60rem; //30
    transform: translate(-50%, -50%) scale(0.5) rotate(-360deg);
    animation: scaleZoom 1.5s cubic-bezier(0.82, 0, 0, 1.03) forwards 4s;
    overflow: hidden;
    transition: 0.3s ease;
    backface-visibility: hidden;
  }
}
@keyframes rolling {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes scaleZoom {
  to {
    opacity: 0.8;
    transform: translate(-50%, -50%) scale(1) rotate(0deg);
  }
}
@keyframes dashoffset {
  to {
    stroke-dashoffset: 0;
  }
}




// skill box on home page

.skill-box{
  position: relative;
  padding: 8rem 22rem;
  overflow: hidden;
  &::before{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background-image: url(../../assets/imgs/background/brain-fill.svg);
    background-size: 80%;
    background-position: 50% 8%;
    background-repeat: no-repeat;
    opacity: .2;
    z-index: 0;
  }
  .skill-inner{
    margin-top:1rem;
  }
}

.skill-item{
 position: relative;
 backdrop-filter: blur(3px);
  padding:.2rem;
  background-color:  rgba(242, 109, 79, .8);
  margin:2rem 0;
  z-index: 2;
  border-top-right-radius: 3rem;
  border-bottom-right-radius: 3rem;
  @include flex;
  justify-content: space-between;
  align-items: center;

  &[data-level='4']{
    width: 40%;
  }
  &[data-level='5']{
    width: 50%;
  }
  &[data-level='6']{
    width: 60%;
  }
  &[data-level='7']{
    width: 70%;
  }
  &[data-level='8']{
    width: 80%;
  }
  &[data-level='9']{
    width: 90%;
  }
  &[data-level='10']{
    width: 100%;
  }
  span{
    display: inline-block;
    padding:0 1rem;
    color:#fff;
    &.skill-title{
      font-size: 1.3rem;
    }
    &.skill-level{
     
     font-size: 1.5rem;
      
    }

  }

}